'use strict';

angular.module('managerApp').config(($routeProvider) => {
    $routeProvider.when('/terminals', {
        templateUrl: 'app/terminals/list.html',
        controller: 'TerminalsController',
        authenticate: 'user',
        hasPermissions: ['TERMINALS']
    }).when('/terminals/partners/id/:id', {
        templateUrl: 'app/terminals/list.html',
        controller: 'TerminalsController',
        authenticate: 'user',
        hasPermissions: ['TERMINALS:GET']
    }).when('/terminals/partners', {
        templateUrl: 'app/terminals/partners/list.html',
        controller: 'TerminalPartnersController',
        authenticate: 'user',
        hasPermissions: ['TERMINALS:GET']
    }).when('/terminals/add', {
        templateUrl: 'app/terminals/add.edit.terminal.html',
        controller: 'TerminalsController',
        authenticate: 'user',
        hasPermissions: ['TERMINALS:POST']
    }).when('/terminals/:id', {
        templateUrl: 'app/terminals/item.html',
        controller: 'TerminalsController',
        authenticate: 'user',
        hasPermissions: ['TERMINALS:GET']
    }).when('/terminals/:id/edit', {
        templateUrl: 'app/terminals/add.edit.terminal.html',
        controller: 'TerminalsController',
        authenticate: 'user',
        hasPermissions: ['TERMINALS:POST']
    });
});
